@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.row {
  display: flex;
  gap: 20px;
  width: 100%;

  @include mix.vp-1024 {
    flex-direction: column;
  }
}

.avatar {
  width: 140px;
  height: 140px;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;

  @include mix.vp-1024 {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.logOutButton {
  width: fit-content;
}

.actions {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 200px;
  padding: 12px 4px;

  @include mix.vp-1024 {
    padding: 16px;
    gap: 20px;
  }
}

.actionButton {
  @include mix.vp-1024 {
    display: none;
  }
}

.actionButtonMobile {
  display: none;

  @include mix.vp-1024 {
    display: block;
    width: 100%;
  }
}
